#zowieFloatingButton,#zowieFloatingButtonMobile, #zowieCallToAction, #zowieCallToActionMobile  {
  z-index: 101 !important;
}

#zowieShadow {
  display: none !important;
}

#chatbotize {
  transition: opacity 300ms;
}

@media (max-width: 719px) {
  body[data-hide-zowie-mobile="1"] #chatbotize {
    opacity: 0;
  }
}

#zowieCallToAction,
#zowieFloatingButton {
  transition: right 300ms ease-out, bottom 300ms ease-out;
}